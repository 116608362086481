<template>
  <div class="detailDialog">
    <el-form  ref="form" :model="form"  label-width="100px">
      <el-form-item class="ipnBor" label="报价截止日期"  prop="inquiryEndDate">
        <el-input disabled v-model="form.inquiryEndDate" style="width:50%"></el-input>
      </el-form-item>
      <el-form-item label="报价" prop="">
        <div class="tableList tableList-dilog">
          <div class="table">
            <el-table :data="form.list" stripe style="width: 100%">
              <el-table-column v-for="(item, index) in form.title" :key='index' :prop='item' :label='item'>
                <template #default="scope">
                  <div v-if="!scope.row[item].isRequired">{{ scope.row[item].value }}</div>
                  <div v-if="scope.row[item].isRequired">
                    <el-input class="SlInput" v-if="item!=='税率'" v-model="scope.row[item].value" placeholder="请输入"></el-input>
                    <el-select v-if="item=='税率'" v-model="scope.row[item].value" placeholder="请选择">
                      <el-option label="1%" value="1%"></el-option>
                      <el-option label="3%" value="3%"></el-option>
                      <el-option label="5%" value="5%"></el-option>
                      <el-option label="9%" value="9%"></el-option>
                      <el-option label="6%" value="6%"></el-option>
                      <el-option label="11%" value="11%"></el-option>
                      <el-option label="13%" value="13%"></el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="报价说明" prop="quoteDesc">
        <el-input type="textarea" v-model="form.quoteDesc" style="width:50%"></el-input>
      </el-form-item>
     <el-form-item label="报价附件"  prop="fileList">
            <el-upload
                :action="actionURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="handleSuccess"
                multiple
                :on-exceed="handleExceed"
                :file-list="form.fileList"
            >
                <el-button  size="small" type="primary" plain>点击上传</el-button>
            </el-upload>
        </el-form-item>
      <el-form-item label="价格有效期" prop="bjyxq">
        <el-date-picker
          v-model="form.bjyxq"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { request,baseURL } from '@/assets/js/http.js'
import { baseHost } from '@/assets/js/PublicData.js'
import dayjs from 'dayjs'
export default {
  name: 'InquiryDetailDialog',
  props: ['ID'],
  data: function () {
    return {
      form: {},
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      tokenHeader: {
        token: localStorage.getItem('token')
      },
    }
  },
  created () {
    this.wlOffer()
  },
  mounted () {},
  methods: {
    // 判断是否只能上传一个文件，超过提示报错
    handleExceed (e) {
      this.$notify.error({
        title: '错误',
        message: '只能上传一个文件'
      })
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.fileList.length; i++) {
        if (this.form.fileList[i].id === file.id) {
          this.form.fileList.splice(i, 1)
        }
      }
    },
    handlePreview (file) {
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id)
    },
     beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.form.fileList.push(element)
        })
      }
    },
    // 根据ID获取物料报价信息
    wlOffer () {
      const id = this.ID
      if (id) {
        request('/api/inquiry/quote/' + id, 'get').then((res) => {
          if (res.code === '200') {
            this.form = res.data
            if(res.data.priceStartTime && res.data.priceEndTime) {
                this.form.bjyxq = [res.data.priceStartTime, res.data.priceEndTime]
            }
            
            this.form.inquiryEndDate = dayjs(this.form.inquiryEndDate).format('YYYY-MM-DD HH:mm:ss')
          }
        })
        // if (this.$route.path === '/SalesLeads/Myprice') {
        //   request('/api/inquiry/getQuoteBySupplier/' + id, 'get').then((res) => {
        //     if (res.code === '200') {
        //       this.form = res.data
        //       this.form.inquiryEndDate = dayjs(this.form.inquiryEndDate).format('YYYY-MM-DD HH:mm:ss')
        //     }
        //   })
        // } else {
        //   request('/api/inquiry/quote/' + id, 'get').then((res) => {
        //     if (res.code === '200') {
        //       this.form = res.data
        //       this.form.inquiryEndDate = dayjs(this.form.inquiryEndDate).format('YYYY-MM-DD HH:mm:ss')
        //     }
        //   })
        // }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.detailDialog{
  .ipnBor{
    &:deep(.el-input__inner){
      border: none;
      background: none;
    }
    &:deep(.el-input.is-disabled .el-input__inner){
      color: #666;
      padding-left: 0;
    }
  }
  .el-input{
    line-height: 38px;
  }
  .Selectmoney{
    float: left;
    width: 80px;
    display:block;
  }
  .SelectInput{
    float: left;
    width: 110px;
  }
  :deep(.el-select .el-input__inner){
      height: 40px;
      //  border: none;
      // padding: 0px;
    }
    :deep(.tableList-dilog){
    padding: 0px !important;
  }
  :deep(.el-textarea){
    width: 100% !important;
  }
  .SlInput{
    width: 100%;
  }
  :deep(.el-upload) {
    display: block!important;
    text-align: left!important;
  }
}
</style>
